import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import ProtectedRoute from './ProtectedRoute';
import Home from './Home'; // Your main component

const App = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<ProtectedRoute component={Home} />} />
    </Routes>
  </Router>
);

export default App;
